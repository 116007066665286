export const twitterHref = "https://twitter.com/ThothPay";
export const facebookHref =
  "https://www.facebook.com/profile.php?id=100095060907471";
export const emailHref = "mailto:service@thothpay.com";
export const instagramHref = "https://example.com/instagram";
export const telegramHref = "https://t.me/+sKj3OO2-6PdhYTM1";
export const signInHref = "https://merchant.thothpay.com";
export const signUpHref = "https://example.com/signup";
export const openAccountHref = "https://merchant.thothpay.com";
export const slide2Href = "https://docs.thothpay.com";
export const slide4Href = "/swagger/index.html";
export const googleHref =
  "https://play.google.com/store/apps/details?id=com.thothpay.app";
export const appHref = "/swagger/index.html";
export const newsHref = "https://docs.thothpay.com";
export const getSupportHref = "https://join.skype.com/w6YCktWrENjK";
export const contactTeamHref = "https://join.skype.com/w6YCktWrENjK";
export const howItWorksHref = "https://example.com/how-it-works";
export const featuredHref = "https://example.com/featured";
export const partnershipHref = "https://example.com/partnership";
export const businessRelationHref = "https://example.com/business-relation";
export const eventsHref = "https://example.com/events";
export const blogHref = "https://example.com/blog";
export const podcastHref = "https://example.com/podcast";
export const inviteFriendHref = "https://example.com/invite-friend";
export const discordHref = "https://example.com/discord";
export const privacyPolicyHref = "/404";
export const termsConditionHref = "/404";
export const individualHref = "https://docs.thothpay.com";
export const enterpriseHref = "https://docs.thothpay.com";
